.close {
    width: 4vw !important;
    height: 3vw !important;
    display: flex !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 1.5vw;
    top: 1vw;
    z-index: 4001;
    color: white
}