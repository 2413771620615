body {
  padding: 0;
  margin: 0;
  color: #45505b !important;
}

.skipButton:hover {
  background-color: #54455b8f;
}

.skipButton {
  bottom: 2vw;
  right: 2vw;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 5vw;
  color: white;
  height: 2vw;
  background-color: #43484bae;
}

.App {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.logo {
  position: fixed;
  top: 0.2vw;
  right: 20px;
  margin: 0;
  height: 40px;
  z-index: 9;
}

.language-selector {
  position: fixed;
  top: 20px;
  left: 20px;
  margin: 0;
  /* height: 40px; */
  padding: 16px;
}

.language-selector p {
  display: none;
  color: #2373b9;
  margin-top: 0;
  text-align: center;
}

/* .language-selector button {
  height: 100%;
} */

.language-selector img {
  height: 40px;
  margin: 0;
}

.select-lang-page {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1vw;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}

.big-title {
  text-align: center;
  font-size: 2rem;
  padding: 0 15px;
}

.select-lang-page .language-selector {
  position: relative;
  top: 0;
  left: 0;
}

.select-lang-page .language-selector p {
  display: block;
}

.backgroundVideo {
  position: relative;
  width: 65%;
  margin: auto;
  height: 0;
  padding-top: 36.5625%;
}

.backgroundVideo>div:not(.content) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.emailContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
  padding: 1vw;
  background-color: red;
}

.backgroundVideo h1 {
  position: absolute;
  top: -80px;
  width: 100%;
  text-align: center;
}

.backgroundVideo video {
  width: 65vw;
  position: absolute;
  top: 0;
  height: 36.5625vw;
}

.backgroundVideo video[poster] {
  object-fit: cover;
  object-position: center;
}

.fullscreen {
  display: flex;
  height: 100vh;
  width: 100vw;
}


.fullscreen-enabled .backgroundVideo {
  width: 100% !important;
  padding-top: 56.25% !important;
}

.fullscreen-enabled .backgroundVideo video {
  width: 100vw;
  height: 56.25vw;
}

.fullscreen-enabled .backgroundVideo .videoActionsContainer {
  width: 100vw !important;
  height: 56.25vw !important;
}

.content {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.toggleOnHover {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.backgroundVideo:hover .content,
.backgroundVideo:hover .toggleOnHover {
  height: 68px;
}

.playPauseBtn {
  width: 145px;
  font-size: 18px;
  /*padding: 10px;*/
  border: none;
  background: #024b98;
  color: #024b98;
  cursor: pointer;
  opacity: 1;
  border-radius: 10px;
}

.playPauseBtn:hover {
  opacity: 1;
  color: white !important;
}

#full:hover {
  opacity: 1;
  color: white !important;
}

#exit:hover {
  opacity: 1;
  color: white !important;
}

.full-web-page {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

.full-screen-video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  float: left;
  margin-right: 25px;
}

.container {
  margin-top: 20px;
}

.descArea {
  background-color: #fff;
  box-shadow: 0 0 3px #c8c8c8;
  padding: 15px;
  box-sizing: border-box;
}

.videoArea {
  position: relative;
}

.lightbox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50vh - 18.28125vw);
  flex-direction: column;
  width: 65vw;
  left: 17.5vw;
  right: 0;
  height: 36.5625vw;
  z-index: 999;
}

.fullscreen-enabled .lightbox {
  top: calc(50vh - 28.125vw);
  width: 100vw;
  left: 0;
  height: 56.25vw;
}

.longbtns {
  display: block;
  color: white !important;
  font-size: 1em;
  height: auto;
  background-color: rgb(2 75 152 / 100%);
  line-height: initial;
  padding: 15px;
  border-radius: 20px;
  align-self: center;
  margin: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.longbtns:hover {
  color: white !important;
  background-color: #98024d59 !important;
}

.longbtns.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0.7);
  }

  50% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0);
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

@media only screen and (max-device-width: 800px) and (orientation: landscape) {
  .select-lang-page {
    width: 40vw;
  }

  .lightbox {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }

  .button {
    font-size: 16px !important;
  }

  .logo {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 0;
    height: 40px;
  }

  .language-selector {
    position: fixed;
    top: 20px;
    left: 20px;
    margin: 0;
    /* height: 40px; */
    padding: 16px;
  }

  .language-selector p {
    display: none;
    color: #2373b9;
    margin-top: 0;
    text-align: center;
  }

  .backgroundVideo {
    width: 65%;
    padding-top: 36.5625%;
  }

  .backgroundVideo h1 {
    position: absolute;
    top: -72px;
    line-height: 36px;
    width: 100%;
    text-align: left;
  }

  /* .backgroundVideo video {
    width: 65vw;
  } */

  .toggleOnHover {
    height: auto;
  }

  .backgroundVideo:hover .content,
  .backgroundVideo:hover .toggleOnHover {
    height: auto;
  }

  .content {
    position: relative;
  }

  .content .MuiBox-root {
    position: relative;
    padding: 16px 0;
  }
}

@media only screen and (max-width: 425px) and (orientation: portrait) {
  .App {
    padding-bottom: 35%;
  }

  .select-lang-page {
    display: flex;
    width: 100vw;
    align-self: center;
    text-align: center;
  }

  .lightbox {
    position: absolute;
    display: flex;
    justify-content: center;
    top: calc(50vh - 25.3215vw);
    left: 5vw;
    flex-direction: column;
    width: 90vw;
    padding-top: 50.625vw;
    /* background: rgba(0, 0, 0, 0.7); */
    /* z-index: 9999; */
  }

  .button {
    font-size: 16px !important;
  }

  .backgroundVideo {
    width: 90%;
    padding-top: 50.625%;
  }

  .backgroundVideo h1 {
    position: absolute;
    top: -72px;
    line-height: 36px;
    width: 100%;
    text-align: left;
  }

  .backgroundVideo video {
    width: 90vw;
    height: 50.625vw;
  }

  .toggleOnHover {
    height: auto;
  }

  .backgroundVideo:hover .content,
  .backgroundVideo:hover .toggleOnHover {
    height: auto;
  }

  .content {
    position: relative;
  }

  .content .MuiBox-root {
    position: relative;
    padding: 16px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .longbtns {
    display: block;
    color: white !important;
    font-size: 0.5em;
    /*width: fit-content;*/
    height: auto;

    background-color: rgb(2 75 152 / 80%);
    line-height: initial;
    padding: 10px;
    border-radius: 20px;
  }

  .ktext {
    font-size: 0.5em !important;
  }
}

.featherlight .featherlight-content {
  /*height: 200px;*/
  width: auto;

  border-radius: 20px;
  background-color: rgb(1 42 86 / 90%);
}

.featherlight .featherlight-close-icon {
  display: none;
}

.button {
  color: #024b98;
  color: white;
  width: auto;
  font-size: 20px;
  display: initial;
  text-align: center;
}

#playPauseBtn {
  color: white;
}

.section-title {
  cursor: pointer;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  background-color: #024b98;
  color: #fff;
  white-space: nowrap;
  border-right: 2px solid #fff;
}

.section {
  position: relative;
  opacity: 0.95;
}

.section.active {
  opacity: 1;
  font-weight: bold;
}

.section.disabled {
  opacity: 0.7;
}

.section-title:hover {
  box-shadow: 0 6px 20px 0px #000;
  min-width: fit-content;
  width: 100%;
  position: absolute;
  z-index: 9999;
  border: none;
}

.section:last-child .section-title {
  border-right: none !important;
}

.videoActionsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: all 0.5s;
  display: block;
}

.hidden {
  opacity: 0;
  display: none;
}

.videoActionsContainer polygon {
  fill: rgb(0 0 0 / 30%);
  transition: all 1s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.videoActionsContainer polygon.disabled {
  cursor: not-allowed;
  fill: rgb(0 0 0 / 70%);
}

.videoActionsContainer polygon:hover:not(.disabled) {
  fill: rgb(0 0 0 / 0%);
  transition: all 0.5s;
}

.calling-container {
  position: absolute;
  left: 50.5% !important;
  top: 60% !important;
  width: 17.5% !important;
  height: 15% !important;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.calling-container button {
  margin: 10%;
  height: 25%;
  width: 25%;
  background: none;
  border: none;
  cursor: pointer;
}