.image {
    width: 100px;
    height: 100px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2000;
    justify-content: flex-end;
    padding:10px
}

.close {
    width: 50px;
    height: 50px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2000;
    color:white
}